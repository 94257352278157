<template>
  <div class="full-page">
    <img class="page-bg" src="@/assets/login/login-bg.png" alt="" />
    <div class="logo">
      <img src="@/assets/public/logo-text.png" alt="" />
    </div>
    <div class="login-container">
      <div class="title-bar">Establecer la contraseña</div>
      <div class="form">
        <div>
          <img src="@/assets/login/user.png" alt="" />
        </div>
        <div class="form-input">
          <input
            v-model="query.loginName"
            type="text"
            placeholder="+57 Por favor ingrese su contraseña"
          />
        </div>
      </div>
      <div class="form">
        <div>
          <img src="@/assets/login/text.png" alt="" />
        </div>
        <div class="form-input" style="display: flex; align-items: center">
          <input
            v-model="query.code"
            style="flex: 1"
            type="text"
            placeholder="introduzca el código de verificación"
          />
          <el-button round @click="getVerify">{{ verifyText }}</el-button>
        </div>
      </div>
      <div class="form">
        <div>
          <img src="@/assets/login/psd.png" alt="" />
        </div>
        <div class="form-input">
          <input
            v-model="query.loginPwd"
            type="password"
            placeholder="Por favor ingrese su contraseña"
          />
        </div>
      </div>
      <div class="form">
        <div>
          <img src="@/assets/login/psd.png" alt="" />
        </div>
        <div class="form-input">
          <input
            v-model="repassWord"
            type="password"
            placeholder="Por favor confirme la nueva contraseña de acceso"
          />
        </div>
      </div>
      <div class="submit">
        <el-button
          type="success"
          style="width: 400px; height: 56px; border-radius: 28px"
          :disabled="!active"
          @click="submitData"
        >
          Siguiente
        </el-button>
      </div>
    </div>

    <el-dialog
      :visible.sync="dialogVisible"
      width="1200px"
      @close="closeDialog"
    >
      <register-info :registerQuery="query"></register-info>
    </el-dialog>
  </div>
</template>

<script>// @ts-nocheck

import registerInfo from './register-info'
export default {
  components: {
    registerInfo
  },

  data() {
    return {
      flag: true,
      query: {
        loginName: '',
        loginPwd: '',
        code: ''
      },
      repassWord: '',
      active: false,
      verifyText: 'Obtener',
      verifyIf: true,
      dialogVisible: false,
      verifycode: ''
    }
  },

  watch: {
    query: {
      handler() {
        this.judgeActive()
      },
      deep: true
    }
  },

  methods: {
    getVerify() {
      // 发送验证码需填写手机号
      if (!this.query.loginName) {
        this.$message('Por favor, rellene el número de teléfono móvil')
        return
      }
      if (!this.flag) {
        return
      }

      console.log('获取验证码')

      var count = 60
      var temir = setInterval(() => {
        if (count == 1) {
          this.verifyText = 'Obtener'
          this.flag = true
          this.verifyIf = true
          clearInterval(temir)
        } else {
          this.flag = false
          this.verifyIf = false
          count--
          this.verifyText = count + 's'
        }
      }, 1000)

      // 发送验证码，调用接口
      this.axios
        .post(
          'https://calabazapets.com/pet_supplies_mall/util/code/getSmsCodeByYunpian',
          {
            loginName: this.query.loginName
          }
        )
        .then((res) => {
          this.verifycode = res.data.result
        })
    },

    submitData() {
      if (!this.repassWord) {
        this.$message.warning('Contraseña inconsistente')
        return
      }
      // if (this.query.code != this.verifycode) {
      //   this.$message.warning('Código incorrecto, inténtalo nuevamente.')
      //   return
      // }
      if (this.repassWord != this.query.loginPwd) {
        this.$message.warning('Contraseña inconsistente')
        return
      }

      this.query.mobile = this.query.loginName
      console.log(this.query)
      this.dialogVisible = true
    },

    judgeActive() {
      if (
        this.query.loginName !== '' &&
        this.query.newPwd !== '' &&
        this.query.code !== ''
      ) {
        this.active = true
      } else {
        this.active = false
      }
    },

    closeDialog() {
      location.reload()
    }
  }
}
</script>

<style lang="scss" scoped>
.full-page {
  position: relative;
  .page-bg {
    display: block;
    width: 100%;
    height: 100vh;

    object-fit: cover;
  }

  .logo {
    position: absolute;
    top: 30px;
    left: 360px;
    width: 272px;
    img {
      width: 100%;
    }
  }

  .login-container {
    position: absolute;
    top: 50%;
    right: 360px;
    padding: 40px 55px;
    background: #fff;
    transform: translateY(-50%);

    .title-bar {
      margin-bottom: 40px;
      font-size: 32px;
      font-weight: bold;
    }

    .form {
      display: flex;
      align-items: center;
      width: 400px;
      height: 56px;
      margin-top: 20px;
      border-bottom: 1px solid #e7e7e7;
      img {
        width: 24px;
        height: 24px;
        margin-right: 20px;
      }
      .form-input {
        flex: 1;
        height: 100%;
        input {
          width: 100%;
          height: 100%;
          border: none;
          outline: none;
        }
        input::-webkit-input-placeholder {
          /* WebKit browsers */
          color: #ccc;
        }
      }
    }

    .toOther {
      display: flex;
      justify-content: space-between;
    }

    .submit {
      margin-top: 40px;
    }
  }
}
</style>
