<template>
  <div>
    <el-cascader
      v-model="currentValue"
      :options="provinceCityOptions"
      @change="handleChangeProvinceCity"
      :props="elCascaderProps"
      placeholder="Por favor, elija"
      class="width-full"
    ></el-cascader>
  </div>
</template>

<script>// @ts-nocheck

import { formatTreeArray } from '@/myCode/myCode'

export default {
  props: ['value'],
  data() {
    return {
      provinceCityOptions: [],
      elCascaderProps: {
        value: 'code',
        label: 'name',
        children: 'childrenDict'
      },
      currentValue:''
    }
  },
  created(){
    this.getProvince()
  },
  methods: {
    getProvince() {
      console.log('获取省市区');
      this.$axios.post('/districtDict/list/auth/tree',{}).then(res => {
        const { result } = res.data
        this.provinceCityOptions = formatTreeArray(result)
        console.log('省市区',res,this.provinceCityOptions);
        
      })
    },

    handleChangeProvinceCity(val) {
      this.currentValue = val
      console.log('当前选择',val);
      
      this.$emit('on-success',val)
    }
  },

  watch:{
    value:{
      handler(){
        this.currentValue = this.value
      },
      deep:true,
      immediate: true,
    }
  }
}
</script>

<style lang="scss" scoped></style>
