<template>
  <!-- <nav-bar> -->
  <div class="edition-center">
    <div class="edit-container">
      <div class="input-list">
        <div class="input-item">
          <p>Nombre de la empresa</p>
          <el-input v-model="query.companyName" placeholder=""></el-input>
        </div>
        <div class="input-item">
          <p>Dirección</p>
          <el-input v-model="query.address" placeholder=""></el-input>
        </div>
        <div class="input-item">
          <p>NIT</p>
          <el-input
            v-model="query.organizationNumber"
            placeholder=""
          ></el-input>
        </div>
        <div class="input-item">
          <p>Fecha de registro de la empresa</p>
          <el-date-picker
            v-model="query.companyCreatedTime"
            value-format="yyyy-MM-dd"
            type="date"
            placeholder=""
            class="width-full"
          ></el-date-picker>
        </div>
        <div class="input-item">
          <p>Persona de contacto</p>
          <el-input v-model="query.contactPerson" placeholder=""></el-input>
        </div>
        <div class="input-item">
          <p>Número de contacto</p>
          <el-input v-model="query.mobile" placeholder=""></el-input>
        </div>
        <div class="input-item">
          <p>Correo electrónico</p>
          <el-input v-model="query.email" placeholder=""></el-input>
        </div>
        <div class="input-item">
          <p>Provincia/ciudad</p>
          <baseRegionSelect @on-success="currentSelect"></baseRegionSelect>
        </div>
        <div class="input-item">
          <p>número de identificación</p>
          <el-input v-model="query.idCard" placeholder=""></el-input>
        </div>
      </div>

      <div class="picture-list">
        <div class="picture-item">
          <div class="title">Cédula (por ambos lados)</div>
          <div class="subtitle">Apoyo a la carga de imágenes / pdf / word</div>
          <div style="display: flex">
            <div class="upload">
              <upload-image
                folder="head"
                :limit="2"
                @listenPostImage="postIdCart"
                :multiple="true"
                :registerStatus="true"
                type="images"
              />
            </div>
            <template v-if="idCardtempUrl">
              <div
                class="imgVisible col-delete"
                v-for="(item, index) in idCardtempUrl"
                :key="index"
              >
                <!-- <img :src="item | filterPicture" alt=""> -->
                <template v-if="item.split('.')[1] === 'pdf'">
                  <a :href="item | filterPicture" target="_blank">
                    <img src="@/assets/public/pdf-icon.png" alt="" />
                  </a>
                </template>
                <template v-else-if="item && item.split('.')[1] === 'docx'">
                  <a :href="item | filterPicture" target="_blank">
                    <img src="@/assets/public/word-icon.png" alt="" />
                  </a>
                </template>
                <template v-else>
                  <img
                    :src="item | filterPicture"
                    @click="$store.commit('SHOW_IMAGE_WALL', item)"
                    alt=""
                  />
                </template>
                <i
                  @click="idCardtempUrl.splice(index, 1)"
                  class="el-icon-error"
                />
              </div>
            </template>
          </div>
        </div>
        <div class="picture-item">
          <div class="title">Cámara de comercio</div>
          <div class="subtitle">Apoyo a la carga de imágenes / pdf / word</div>
          <div style="display: flex">
            <div class="upload">
              <upload-image
                folder="head"
                @listenPostImage="postBusinessImage"
                :registerStatus="true"
                type="images"
              />
            </div>
            <div class="imgVisible" v-if="query.businessLicenseImageUrl">
              <!-- <img :src="query.businessLicenseImageUrl | filterPicture" alt=""> -->
              <template
                v-if="
                  query.businessLicenseImageUrl &&
                  query.businessLicenseImageUrl.split('.')[1] === 'pdf'
                "
              >
                <a
                  :href="query.businessLicenseImageUrl | filterPicture"
                  target="_blank"
                >
                  <img src="@/assets/public/pdf-icon.png" alt="" />
                </a>
              </template>
              <template
                v-else-if="
                  query.businessLicenseImageUrl &&
                  query.businessLicenseImageUrl.split('.')[1] === 'docx'
                "
              >
                <a
                  :href="query.businessLicenseImageUrl | filterPicture"
                  target="_blank"
                >
                  <img src="@/assets/public/word-icon.png" alt="" />
                </a>
              </template>
              <template v-else>
                <img
                  :src="query.businessLicenseImageUrl | filterPicture"
                  @click="
                    $store.commit(
                      'SHOW_IMAGE_WALL',
                      query.businessLicenseImageUrl
                    )
                  "
                  alt=""
                />
              </template>
            </div>
          </div>
        </div>
        <div class="picture-item">
          <div class="title">RUT</div>
          <div class="subtitle">Apoyo a la carga de imágenes / pdf / word</div>
          <div style="display: flex">
            <div class="upload">
              <upload-image
                folder="head"
                @listenPostImage="postTaxImage"
                :registerStatus="true"
                type="images"
              />
            </div>
            <div class="imgVisible" v-if="query.taxCertificateImageUrl">
              <template
                v-if="
                  query.taxCertificateImageUrl &&
                  query.taxCertificateImageUrl.split('.')[1] === 'pdf'
                "
              >
                <a
                  :href="query.taxCertificateImageUrl | filterPicture"
                  target="_blank"
                >
                  <img src="@/assets/public/pdf-icon.png" alt="" />
                </a>
              </template>
              <template
                v-else-if="
                  query.taxCertificateImageUrl &&
                  query.taxCertificateImageUrl.split('.')[1] === 'docx'
                "
              >
                <a
                  :href="query.taxCertificateImageUrl | filterPicture"
                  target="_blank"
                >
                  <img src="@/assets/public/word-icon.png" alt="" />
                </a>
              </template>
              <template v-else>
                <img
                  :src="query.taxCertificateImageUrl | filterPicture"
                  @click="
                    $store.commit(
                      'SHOW_IMAGE_WALL',
                      query.taxCertificateImageUrl
                    )
                  "
                  alt=""
                />
              </template>
            </div>
          </div>
        </div>
      </div>

      <div class="submit">
        <el-button
          type="success"
          round
          style="width: 400px"
          :loading="btnLoading"
          @click="submitData"
        >
          Regístrese
        </el-button>
      </div>
    </div>
  </div>
  <!-- </nav-bar> -->
</template>

<script>
// @ts-nocheck

import navBar from '@/components/navBar/index.vue'
import UploadImage from '@/components/upload/image'
import baseRegionSelect from '@/components/base-region-select/index'

export default {
  props: ['registerQuery', 'editQuery'],

  components: {
    navBar,
    UploadImage,
    baseRegionSelect
  },

  data() {
    return {
      input: '',
      query: {
        loginName: '',
        loginPwd: '',
        mobile: '',
        email: '',
        companyName: '',
        address: '',
        organizationNumber: '',
        companyCreatedTime: '',
        contactPerson: '',
        idCardImageUrl: '',
        businessLicenseImageUrl: '',
        taxCertificateImageUrl: '',
        code: ''
      },
      idCardtempUrl: [],
      forbidClick: true,
      btnLoading: false
    }
  },

  created() {
    if (this.registerQuery) {
      this.query.loginName = this.registerQuery.loginName

      // 判断注册账户是否为邮箱
      let emailPattern = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/
      if (emailPattern.test(this.registerQuery.loginName)) {
        this.query.email = this.registerQuery.loginName
      } else {
        this.query.mobile = this.registerQuery.loginName
      }

      this.query.loginPwd = this.registerQuery.loginPwd
      this.query.code = this.registerQuery.code
    } else if (this.editQuery) {
      console.log(this.editQuery)
      for (let key in this.query) {
        this.query[key] = this.editQuery[key]
      }

      this.idCardtempUrl = this.query.idCardImageUrl.split(',')
    }
  },

  methods: {
    postIdCart(url) {
      console.log(this.idCardtempUrl)

      this.idCardtempUrl.push(url)
    },

    postBusinessImage(url) {
      console.log(url)
      this.query.businessLicenseImageUrl = url
    },

    postTaxImage(url) {
      console.log(url)
      this.query.taxCertificateImageUrl = url
    },

    submitData() {
      for (let key in this.query) {
        if (
          !this.query[key] &&
          key != 'idCardImageUrl' &&
          key != 'taxCertificateImageUrl' &&
          key != 'businessLicenseImageUrl' &&
          key != 'code' &&
          key != 'loginPwd'
        ) {
          //code在再次申请注册时不需要传
          console.log(key)
          this.$message('Por favor, complete la información requerida') //请填写所需资料
          return
        }
      }
      console.log('信息完整，进行下一步判断')

      if (this.idCardtempUrl.length) {
        this.query.idCardImageUrl = this.idCardtempUrl.join(',')
      }

      this.btnLoading = true

      // 修改注册信息
      if (this.editQuery) {
        this.axios
          .post('/member/updateCertification', this.query)
          .then((res) => {
            this.$message(
              'La solicitud de registro ha sido presentada, Por favor sea paciente Esperando revisión'
            ) // 注册申请已提交，请耐心等待审核
            setTimeout(() => {
              this.btnLoading = false
              location.reload()
            }, 4000)
          })
          .catch((error) => {
            this.btnLoading = false
            setTimeout(() => {
              location.reload()
            }, 5000)
          })

        return
      }

      // 防止用户二次点击注册
      if (!this.forbidClick) {
        return
      }
      this.forbidClick = false

      console.log('首次注册信息',this.query);
      

      // 首次注册信息
      this.axios
        .post('/member/auth/register', this.query)
        .then((res) => {
          // console.log(res)
          this.$message(
            'La solicitud de registro ha sido presentada, Por favor sea paciente Esperando revisión'
          ) // 注册申请已提交，请耐心等待审核
          setTimeout(() => {
            this.btnLoading = false
            this.$router.push('/login')
          }, 4000)
        })
        .catch((error) => {
          this.btnLoading = false
          setTimeout(() => {
            location.reload()
          }, 5000)
        })
    },

    currentSelect(e) {
      console.log('当前选择', e)
      if (e.length) {
        this.query.province = e[0]
        this.query.region = e[1]
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.edition-center {
  width: 1200px;
  margin: 0 auto;

  .edit-container {
    padding: 30px 90px;
    .input-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .input-item {
        width: 460px;
        margin-top: 20px;
        p {
          margin-bottom: 10px;
        }

        p::after {
          content: "*";
          margin-left: 2px;
          color: red;
        }
      }
    }

    .picture-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-top: 50px;
      .picture-item {
        width: 460px;
        margin-bottom: 20px;
        .subtitle {
          color: #979797;
          font-size: 14px;
        }
        .upload,
        .imgVisible {
          display: inline-block;
          width: 120px;
          margin: 10px 20px 0 0;
          img {
            display: block;
            width: 100%;
            height: 120px;

            object-fit: cover;
          }
        }
      }
    }

    .submit {
      margin: 50px 0;
      text-align: center;
    }
  }

  // 删除图标
  .col-delete {
    position: relative;
    .el-icon-error {
      position: absolute;
      top: -7px;
      right: -7px;
      display: none;
      color: #999;
      font-size: 20px;
      cursor: pointer;
      &:hover {
        color: #333;
      }
    }
    &:hover {
      .el-icon-error {
        display: block;
      }
    }
  }
}
</style>
